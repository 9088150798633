.admin {
  .form-item {
    display: flex;
  }

  .error-message {
    color: red;
  }

  .form-item {
    margin-bottom: 16px;

    label {
      width: 90px;
      display: inline-block;
    }
  }

  .title-input {
    width: 500px;
  }

  .message-input {
    width: 500px;
    height: 90px;
  }

  .ant-select-selector {
    min-width: 200px;
  }

  .save-section {
    margin: 24px 0;
  }
}
