.user {
    .go-to-cart-button {
        padding: 0;
    }

    .cart-state {
        font-size: 18px;
        margin-right: 18px;
        margin-top: -4px;
    }
}