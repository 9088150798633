.report-table-packaging .table-format {
    background: #b3b3b3;
}

.report-table-packaging td, .report-table-packaging th {
    padding: 6px;
    border: 1px solid #dedede;
}

.report-table-packaging td.signature {
    padding: 6px 6px 6px 32px;
    width: 150px;
}

.report-table-packaging .singlePhotoItem {
    margin-bottom: 1px;
}