.user {
    .header {
        position: fixed;
        z-index: 1;
        width: 100%;
        height: 64px;
        display: flex;
        justify-content: space-between;
        background: #fff;
        border-bottom: 1px solid #dedede;
        padding: 16px 64px;
    }

    .ant-btn.logout-button {
        padding: 0;
    }
}