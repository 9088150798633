.user {
    .session-header-wrapper {
        padding: 42px;
        width: 800px;
        text-align: center;
        margin: 0 auto;
    }

    .ant-divider.session-header__divider {
        width: 389px;
        min-width: 389px;
        margin: 24px auto;
    }

    .page-name {
        font-size: 20px;
        text-transform: uppercase;
    }

    .title {
        font-size: 32px;
        color: #afd8f8;
    }

    .message {
        font-size: 16px;
        color: #9a9a9a;
    }

    .back-to-album-button {
        position: absolute;
        left: 64px;
        padding: 0;
    }
}