.user {
    .single-view .image-wrapper {
        display: flex;
        justify-content: center;

        img {
            max-width: 100%;
            height: 100%;
        }
    }
}
