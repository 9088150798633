.fullCart_productItem {
  display: flex;
  margin-bottom: 24px;
}

.fullCart_image {
  width: 35%;
  height: 170px;
}

.fullCart_priceItems {
  margin-left: 16px;
}

.fullCart_imageName {
  width: 35%;
}