.user {
    .ant-btn.go-back-button {
        padding: 0;
        margin-bottom: 16px;
    }

    .single-product-nav {
        justify-content: center;
        display: flex;
        height: 100%;
        align-items: center;
    }

    .single-product-nav .ant-btn {
        color: #727272;
        font-size: 30px;
    }
}