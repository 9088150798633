.admin-image-outer-wrapper {
    margin: 8px;
    border: 1px solid #eee;
    padding: 16px;
}

.admin-image-inner-wrapper {
    height: 180px;
    display: flex;
    justify-content: center;
}

.admin-image-inner-wrapper img {
    max-width: 100%;
    height: 100%;
}