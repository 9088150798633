.user {
    .user-login-wrapper {
        height: 100vh;
        width: 100vw;
        background: url('../../../src/images/login-background.jpg');
        -webkit-background-size: cover;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .login-card {
        width: 443px;
        background: white;
        padding: 24px 24px 40px;
        border: 1px solid #707070;
        box-shadow: 0 3px 6px #00000029;
        position: relative;
    }

    .login-card__title {
        display: flex;
        justify-content: center;
        font-size: 20px;
    }

    .login-card__color {
        color: #1890ff;
    }

    .login-button {
        width: 100%;
        margin-top: 24px;
    }

    .login-form-wrapper {
        padding: 0 50px;
    }

    .ant-divider-horizontal.login-divider {
        margin: 32px 0 40px;
    }

    .ant-input.error {
        border: 1px solid red;
    }

    .login-error {
        color: red;
        margin-top: 8px;
        text-align: center;
    }
}
