.admin {
  .price-item {
    width: 80px;

    &.error {
      border-color: red;
    }
  }

  .format-item {
    margin-bottom: 8px;
  }
}