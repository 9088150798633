.user {
    .ant-card-cover {
        display: flex;
        justify-content: center;
        padding-top: 24px
    }

    .grid-view .ant-card-cover > * {
        display: block;
        max-width: 100%;
        max-height: 230px;
        width: auto !important;
    }

    .grid-view .image {
        cursor: pointer;
    }

    .single-view .ant-card-cover {
        padding-top: 0;
    }

    .single-view .ant-card-cover > * {
        display: block;
        max-width: 100%;
        max-height: 556px;
        width: auto;
    }

    .image__image-name {
        text-align: center;
        margin-bottom: 16px;
    }

    .ant-card-body {
        padding: 16px 24px 24px;
    }
}