.admin {
    padding-top: 64px;
}

.admin .sticky-menu {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 64px;
    display: flex;
    justify-content: flex-end;
    background: #fff;
    border-bottom: 1px solid #dedede;
    padding: 16px 64px;
    top: 0;
}

.ant-btn.logout-button {
    padding: 0;
}