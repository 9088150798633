.user {
    .price-item__price {
        color: #b3b3b3;
    }

    .price-item-table-row td {
        padding-bottom: 8px;
    }

    .price-label-table-cell {
        padding: 0 16px 0 0;
    }
}