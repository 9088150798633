.report-table .table-format {
    background: #b3b3b3;
}

.report-table td {
    padding: 6px;
}

.report-table td.table-count {
    padding: 6px 6px 6px 32px;
}