.user {
    .with-header {
        padding-top: 64px;
    }

    .content-wrapper {
        background: #f2f2f2;
        padding: 32px 64px;
    }

    .content-wrapper.single-view {
        background: #ffffff;
    }

    .content-wrapper.single-view .ant-card-bordered {
        border: none;
    }

}